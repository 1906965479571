<template>
  <div>
    <div
      class="
        consume-bar
        margin-top-bottom-twenty
        box-shadow-light-grey
        border-radius-6
        padding-right-left-5
      "
    >
      <el-menu
        :default-active="activeSubMenu"
        active-text-color="#00bf8a"
        mode="horizontal"
        class=""
        router
      >
        <el-menu-item
          class="font-eighteen margin-right-23"
          index="/statistics/finance/everydayData"
          @click.native="handleTabClick('/statistics/finance/everydayData')"
          >财务日情况报表</el-menu-item
        >
        <el-menu-item
          class="font-eighteen margin-right-23"
          index="/statistics/finance/bookData"
          @click.native="handleTabClick('/statistics/finance/bookData')"
          >财务书籍统计</el-menu-item
        >
        <el-menu-item
          class="font-eighteen margin-right-23"
          index="/statistics/finance/salaryData"
          @click.native="handleTabClick('/statistics/finance/salaryData')"
          >薪资管理</el-menu-item
        >
        <el-menu-item
          class="font-eighteen margin-right-23"
          index="/statistics/finance/profitData"
          @click.native="handleTabClick('/statistics/finance/profitData')"
          >利润统计</el-menu-item
        >
      </el-menu>
    </div>
    <div class="consume-part">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: "finance",
  data() {
    return {
      tabName: this.$route.path,
      activeSubMenu: this.$route.path,
    };
  },
  watch: {
    $route(newVal) {
      this.activeSubMenu = newVal.path;
      this.tabName = newVal.path;
    },
  },
  methods: {
    handleTabClick(tab) {
      this.tabName = tab;
    },
  },
};
</script>

<style lang="scss" scoped>
.consume-bar {
  background: #fff;
  /deep/ .el-menu-item,
  .el-submenu__title {
    height: 34px;
  }
}
.el-menu--horizontal > .el-menu-item {
  height: 52px;
  line-height: 52px;
}
.consume-part {
  box-shadow: 0px 0px 6px 0px #f1f4f6;
}
</style>
